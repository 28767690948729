import React from "react";
import "./addManualData.scss";
import { useState, useEffect } from "react";
import { IconButton } from "@mui/material";
import { Icon } from "@iconify/react";
import FloatingNotifications from "../../../utilities/FloatingNotifications";
import PopUp from "../../patientViewPage/PopUp";
import { useUpdateDeviceDetaMutation } from "../../../app/api/rpmApi";
import { generalHandleValueChanges } from "../../Careteam/helperfunctions";
import { setDate } from "date-fns";
function AddManualManual({ popUpOpen, setPopUpOpen, id, tab }) {
  const initialBP = {
    DiastolicBloodPressure: "",
    SystolicBloodPressure: "",
    PulseValue: "",
    Notes: "",
  };
  const initialWeigth = {
    HeightValue: "",
    WeightValue: "",
    Notes: "",
  };
  const initialTemp = {
    mode: "",
    unit: "",
    value: "",
    Height: "",
    Notes: "",
  };
  const [addData, { data, isLoading, error }] = useUpdateDeviceDetaMutation();

  const [addBP, setBP] = useState({
    DiastolicBloodPressure: "",
    SystolicBloodPressure: "",
    PulseValue: "",
    Notes: "",
  });
  const [addWeight, setWeigth] = useState({
    HeightValue: "",
    WeightValue: "",
    Notes: "",
  });
  const [addTemp, setAddTemp] = useState({
    mode: "",
    unit: "°F",
    value: "",
    Height: "",
    Notes: "",
  });

  const cancelfn = () => {
    setBP(initialBP);
    setWeigth(initialWeigth);
    setAddTemp(initialTemp);
    setPopUpOpen(false);
  };
  const saveFn = () => {
    if (tab === "Weight") {
      if (addWeight.HeightValue === "" || addWeight?.WeightValue === "") {
        FloatingNotifications("error", "Fill required fields to proceed");

        return;
      }
      addData({
        PatientId: id,
        data: {
          tz: "UTC-4",
          data_type: "scale_gen2_measure",
          Heigth: addWeight.HeightValue,
          wt: addWeight.WeightValue,
          Source: "Manual",
          Notes: addWeight.Notes,
          ts: Date.now(),
        },
      });
    } else if (tab === "Blood Pressure") {
      if (
        addBP.PulseValue === "" ||
        addBP.SystolicBloodPressure === "" ||
        addBP.DiastolicBloodPressure === ""
      ) {
        FloatingNotifications("error", "Fill required fields to proceed");

        return;
      }
      addData({
        PatientId: id,
        data: {
          data_type: "bpm_gen2_measure",
          pul: addBP.PulseValue,
          tz: "UTC-4",
          sys: addBP.SystolicBloodPressure,
          dia: addBP.DiastolicBloodPressure,
          Source: "Manual",
          Notes: addBP.Notes,
          ts: Date.now(),
        },
      });
    } else if (tab === "Temperature") {
      if (
        addTemp.mode === "" ||
        addTemp.value === "" ||
        addTemp.Height === ""
      ) {
        FloatingNotifications("error", "Fill required fields to proceed");

        return;
      }
      addData({
        PatientId: id,
        data: {
          ble_addr: "a4:c1:38:ba:0d:80",
          model_number: "AOJ 20A",
          history: [
            {
              mode: addTemp.mode,
              unit: "F",
              value: addTemp.value,
              ts: Date.now(),
            },
          ],
          battery_level: "Full level",
          timezone: "-0500",
          timestamp: Date.now(),
        },
      });
    }
  };
  useEffect(() => {
    if (error) {
      FloatingNotifications("error", error?.data?.msg);
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      FloatingNotifications("success", "Data added successfully");
      setBP(initialBP);
      setWeigth(initialWeigth);
      setPopUpOpen(false);
    }
  }, [data]);

  return (
    <div className="addManualData">
      {popUpOpen && (
        <PopUp cancelfn={cancelfn} width={"80vw"} id={id}>
          <div>
            {tab === "Weight" && (
              <div className="Manualgrid">
                <p className="colSpan8 txtHead">
                  <Icon
                    icon="fluent:text-bullet-list-square-edit-24-regular"
                    color="#222"
                    width="21"
                    height="21"
                  />
                  Add Weight
                </p>

                <div className="colSpan4 gridBoX">
                  <div className=" manualElements">
                    <label className="manualElementsLabel">
                      Weight Value (mg) *
                    </label>
                    <input
                      type="number"
                      // value={device?.effectiveDate?.slice(0, 16)}
                      onChange={(event) =>
                        generalHandleValueChanges(
                          event,
                          setWeigth,
                          "WeightValue"
                        )
                      }
                    />
                  </div>
                </div>
                <div className="colSpan4 gridBoX">
                  <div className=" manualElements">
                    <label className="manualElementsLabel">
                      Height Value (cm) *
                    </label>
                    <input
                      type="number"
                      // value={device?.effectiveDate?.slice(0, 16)}
                      onChange={(event) =>
                        generalHandleValueChanges(
                          event,
                          setWeigth,
                          "HeightValue"
                        )
                      }
                    />
                  </div>
                </div>

                <div className="colSpan8 gridBoX">
                  <div className=" manualElements">
                    <label className="manualElementsLabel">Notes </label>
                    <input
                      type="text"
                      // value={device?.effectiveDate?.slice(0, 16)}
                      onChange={(event) =>
                        generalHandleValueChanges(event, setWeigth, "Notes")
                      }
                    />
                  </div>
                </div>
              </div>
            )}
            {tab === "Blood Pressure" && (
              <div className="Manualgrid">
                <p className="colSpan8 txtHead">
                  <Icon
                    icon="fluent:text-bullet-list-square-edit-24-regular"
                    color="#222"
                    width="21"
                    height="21"
                  />
                  Add Blood Pressure
                </p>

                <div className="colSpan4 gridBoX">
                  <div className=" manualElements">
                    <label className="manualElementsLabel">
                      Blood Pressure (Systolic) Value *
                    </label>
                    <input
                      type="number"
                      // value={device?.effectiveDate?.slice(0, 16)}
                      onChange={(event) =>
                        generalHandleValueChanges(
                          event,
                          setBP,
                          "SystolicBloodPressure"
                        )
                      }
                    />
                  </div>
                </div>
                <div className="colSpan4 gridBoX">
                  <div className=" manualElements">
                    <label className="manualElementsLabel">
                      Blood Pressure (Diastolic) Value *
                    </label>
                    <input
                      type="number"
                      // value={device?.effectiveDate?.slice(0, 16)}
                      onChange={(event) =>
                        generalHandleValueChanges(
                          event,
                          setBP,
                          "DiastolicBloodPressure"
                        )
                      }
                    />
                  </div>
                </div>
                <div className="colSpan4 gridBoX">
                  <div className=" manualElements">
                    <label className="manualElementsLabel">Pulse Value *</label>
                    <input
                      type="number"
                      // disabled={true}
                      // value={device?.effectiveDate?.slice(0, 16)}
                      onChange={(event) =>
                        generalHandleValueChanges(event, setBP, "PulseValue")
                      }
                    />
                  </div>
                </div>
                <div className="colSpan8 gridBoX">
                  <div className=" manualElements">
                    <label className="manualElementsLabel">Notes *</label>
                    <input
                      type="text"
                      // value={device?.effectiveDate?.slice(0, 16)}
                      onChange={(event) =>
                        generalHandleValueChanges(event, setBP, "Notes")
                      }
                    />
                  </div>
                </div>
              </div>
            )}
            {tab === "Temperature" && (
              <div className="Manualgrid">
                <p className="colSpan8 txtHead">
                  <Icon
                    icon="fluent:text-bullet-list-square-edit-24-regular"
                    color="#222"
                    width="21"
                    height="21"
                  />
                  Add Temperature
                </p>

                <div className="colSpan4 gridBoX">
                  <div className=" manualElements">
                    <label className="manualElementsLabel">Mode *</label>
                    <input
                      type="text"
                      // value={device?.effectiveDate?.slice(0, 16)}
                      onChange={(event) =>
                        generalHandleValueChanges(event, setAddTemp, "mode")
                      }
                    />
                  </div>
                </div>
                <div className="colSpan4 gridBoX">
                  <div className=" manualElements">
                    <label className="manualElementsLabel">Value (°F)*</label>
                    <input
                      type="number"
                      // value={device?.effectiveDate?.slice(0, 16)}
                      onChange={(event) =>
                        generalHandleValueChanges(event, setAddTemp, "value")
                      }
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="addManualData-button">
              <IconButton
                style={{
                  borderRadius: "4px",
                  padding: "1rem",
                  fontSize: "calc(6.32px + 0.632 * 1vw)",
                  color: "#fff",
                  fontWeight: "600",
                  backgroundColor: "#7A5DDA",
                }}
                disabled={isLoading}
                className="btn-manualData"
                onClick={() => saveFn()}
              >
                Add data
              </IconButton>
            </div>
          </div>
        </PopUp>
      )}
    </div>
  );
}

export default AddManualManual;
