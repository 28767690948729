import React from "react";
import { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

import AddDevices from "./addDevices/AddDevices";
import AddManualData from "./addManualData/AddManualData";
import SetThreshold from "./setThreshold/SetThreshold";
import { useGetDeviceDetaMutation } from "../../app/api/rpmApi";
import CarePlanErrorMessage from "../Careteam/CarePlanErrorMessage";
import "./vitalsReading.scss";
const VitalsReading = ({ id }) => {
  const [tabs, setTabs] = useState("Blood Pressure");
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [addDataPopUpOpen, setaddDataPopUpOpen] = useState(false);
  const [ThresholdPopUpOpen, setThresholdPopUpOpen] = useState(false);
  const [getDeviceData, { data: allDeviceData }] = useGetDeviceDetaMutation();
  const [bpmData, setBpmData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataDuration, setDataDuration] = useState(24);
  const [scaleData, setScaleData] = useState([]);
  const [temperatureData, setTemperatureData] = useState([]);

  const onClickRefreash = () => {
    if (id) {
      setLoading(true);
      getDeviceData({ PatientId: id }).then(() => setLoading(false));
    }
    return;
  };

  function isWithinWeeksBeforeToday(targetDate, weeks) {
    const today = new Date();
    const target = new Date(targetDate);

    if (isNaN(target.getTime())) {
      throw new Error("Invalid targetDate");
    }

    const timeDifference = today - target;

    const maxDifference = weeks * 7 * 24 * 60 * 60 * 1000;

    return timeDifference <= maxDifference;
  }

  useEffect(() => {
    onClickRefreash();
  }, [id, getDeviceData]);

  useEffect(() => {
    if (!allDeviceData) return;
    let bpmFilteredData = allDeviceData
      ?.filter((el) => el.device_type === "bpm_gen2_measure")[0]
      ?.deviceData?.filter((el) => {
        return isWithinWeeksBeforeToday(new Date(el.ts), dataDuration);
      });
    let scaleFilteredData = allDeviceData
      ?.filter((el) => el.device_type === "scale_gen2_measure")[0]
      ?.deviceData?.filter((el) => {
        return isWithinWeeksBeforeToday(new Date(el.ts), dataDuration);
      });
    let tempFilteredData = allDeviceData
      ?.filter((el) => el.device_type === "thermo_measure")[0]
      ?.deviceData?.filter((el) => {
        return isWithinWeeksBeforeToday(new Date(el.ts), dataDuration);
      });
    setBpmData(bpmFilteredData);
    setScaleData(scaleFilteredData);
    setTemperatureData(tempFilteredData);
  }, [allDeviceData, dataDuration]);

  return (
    <div className="vitalspageContainer">
      <AddDevices popUpOpen={popUpOpen} setPopUpOpen={setPopUpOpen} id={id} />
      <AddManualData
        popUpOpen={addDataPopUpOpen}
        setPopUpOpen={setaddDataPopUpOpen}
        id={id}
        tab={tabs}
      />
      <SetThreshold
        popUpOpen={ThresholdPopUpOpen}
        setPopUpOpen={setThresholdPopUpOpen}
        id={id}
        tabs={tabs}
      />
      <div className="vhead">
        <div className="vtitle">Vital Readings</div>
        <div className="vnav">
          <button className="vnavBtn" onClick={() => setPopUpOpen(true)}>
            <Icon
              icon="mdi:mobile-phone-settings-variant"
              color="white"
              width="20"
              height="20"
            />
            <p>Device Setup</p>
          </button>

          <button className="boxBtn refreshBtn" onClick={onClickRefreash}>
            <Icon icon="tabler:refresh" color="white" width="20" height="20" />
            <p>Refresh {tabs} Readings</p>
          </button>
          <button className="boxBtn " onClick={() => setaddDataPopUpOpen(true)}>
            <Icon icon="octicon:plus-16" color="white" width="20" height="20" />{" "}
          </button>
          <button
            className="boxBtn"
            onClick={() => setThresholdPopUpOpen(true)}
          >
            <Icon
              icon="material-symbols:settings"
              color="white"
              width="20"
              height="20"
            />{" "}
          </button>
        </div>
      </div>
      <div className="vBody">
        <div className="vtab">
          <div
            onClick={() => setTabs("Blood Pressure")}
            className={classNames(
              "tabBtn",
              tabs === "Blood Pressure" ? "active" : ""
            )}
          >
            <Icon
              icon="healthicons:blood-pressure-2-outline"
              color={tabs === "Blood Pressure" ? "#fff" : "#9b86e3"}
              width="26"
              height="26"
            />
            <p>Blood Pressure</p>
          </div>

          <div
            className={classNames("tabBtn", tabs === "Weight" ? "active" : "")}
            onClick={() => setTabs("Weight")}
          >
            <Icon
              icon="mdi:weight-lifter"
              color={tabs === "Weight" ? "#fff" : "#9b86e3"}
              width="26"
              height="26"
            />
            <p>Weight</p>
          </div>
          <div
            className={classNames(
              "tabBtn",
              tabs === "Temperature" ? "active" : ""
            )}
            onClick={() => setTabs("Temperature")}
          >
            <Icon
              icon="tabler:temperature-sun"
              color={tabs === "Temperature" ? "#fff" : "#9b86e3"}
              width="26"
              height="26"
            />
            <p>Temperature</p>
          </div>

          <div
            className={classNames(
              "tabBtn",
              tabs === "Blood Glucose" ? "active" : ""
            )}
            onClick={() => setTabs("Blood Glucose")}
          >
            <Icon
              icon="fa6-solid:hand-holding-droplet"
              color={tabs === "Blood Glucose" ? "#fff" : "#9b86e3"}
              width="26"
              height="26"
            />
            <p>Blood Glucose</p>
          </div>
        </div>
        <div className="boxContainer">
          <div className="boxGridBody">
            <div className="subNav">
              {/* <div className="period">
                <p className="periodTxt">Period:</p>
                <select placeholder="All" />
              </div> */}
              <p className="note">
                <b>Note:</b> Taken Date & Time displayed below are in patient’s
                time zone (EST)
              </p>
              <div className="weekgrid">
                <button
                  className={classNames(
                    "dur",
                    dataDuration === 1 ? "activeDuration" : ""
                  )}
                  onClick={() => setDataDuration(1)}
                >
                  Week
                </button>
                <button
                  className={classNames(
                    "dur",
                    dataDuration === 2 ? "activeDuration" : ""
                  )}
                  onClick={() => setDataDuration(2)}
                >
                  2 Weeks
                </button>
                <button
                  className={classNames(
                    "dur",
                    dataDuration === 4 ? "activeDuration" : ""
                  )}
                  onClick={() => setDataDuration(4)}
                >
                  Month
                </button>
                <button
                  className={classNames(
                    "dur",
                    dataDuration === 12 ? "activeDuration" : ""
                  )}
                  onClick={() => setDataDuration(12)}
                >
                  3 Months
                </button>
                <button
                  className={classNames(
                    "dur",
                    dataDuration === 24 ? "activeDuration" : ""
                  )}
                  onClick={() => setDataDuration(24)}
                >
                  6 Months
                </button>
                <button
                  className={classNames(
                    "dur",
                    dataDuration === 48 ? "activeDuration" : ""
                  )}
                  onClick={() => setDataDuration(48)}
                >
                  1 Year
                </button>
              </div>
            </div>
            {tabs === "Blood Pressure" && (
              <>
                {!bpmData || bpmData?.length === 0 ? (
                  <CarePlanErrorMessage msg="Patient has not entered data for Blood Pressure yet!" />
                ) : (
                  <div className="gridBody">
                    <div className="gridHeader">
                      <div className="gridHead">Period</div>
                      <div className="gridHead">Taken Date & Time</div>
                      <div className="gridHead">Systolic Value (mmHg)</div>
                      <div className="gridHead">Diastolic Value (mmHg)</div>
                      <div className="gridHead">Pulse Value (Beats/min)</div>
                      <div className="gridHead">Min Systolic</div>
                      <div className="gridHead">Max Systolic</div>
                      <div className="gridHead">Min Diastolic</div>
                      <div className="gridHead">Max Diastolic</div>
                      <div className="gridHead">Pulse Min Value</div>
                      <div className="gridHead">Pulse Max Value</div>
                      <div className="gridHead">Source</div>
                      <div className="gridHead">Notes</div>
                      <div className="gridHead">Action</div>
                    </div>
                    <div className="gridContent">
                      {loading ? (
                        <>&nbsp;</>
                      ) : (
                        <>
                          {bpmData?.map((el) => (
                            <>
                              <div className="gridBox">{el?.Period}</div>
                              <div className="gridBox">
                                {new Date(el.ts).toLocaleString()}
                              </div>
                              <div className="gridBox">{el?.sys}</div>
                              <div className="gridBox">{el?.dia}</div>
                              <div className="gridBox">{el?.pul}</div>
                              <div className="gridBox">100</div>
                              <div className="gridBox">150</div>
                              <div className="gridBox">50</div>
                              <div className="gridBox">100</div>
                              <div className="gridBox">60</div>
                              <div className="gridBox">110</div>
                              <div className="gridBox">{el?.Source}</div>
                              <div className="gridBox">{el?.Notes}</div>
                              <div className="gridBox">
                                <Icon icon="material-symbols:delete" />
                              </div>
                            </>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
            {tabs === "Weight" && (
              <>
                {!scaleData || scaleData?.length === 0 ? (
                  <CarePlanErrorMessage msg="Patient has not entered data for weight yet!" />
                ) : (
                  <div className="gridBody grid12">
                    <div className="gridHead">Taken Date & Time</div>
                    <div className="gridHead">KG Value </div>
                    <div className="gridHead">BMI Value</div>
                    <div className="gridHead">Min Weight</div>
                    <div className="gridHead">Max Weight</div>
                    <div className="gridHead">Underweight</div>
                    <div className="gridHead">Normal</div>
                    <div className="gridHead">Overweight</div>
                    <div className="gridHead">Obese</div>
                    <div className="gridHead">Source</div>
                    <div className="gridHead">Notes</div>
                    <div className="gridHead">Action</div>
                    <div className="gridContent">
                      {loading ? (
                        <>&nbsp;</>
                      ) : (
                        <>
                          {scaleData?.map((el) => (
                            <>
                              <div className="gridBox">
                                {new Date(el.ts).toLocaleString()}
                              </div>
                              <div className="gridBox">{el?.wt}</div>
                              <div className="gridBox">
                                {el?.BMI}({el?.BMI_Category})
                              </div>
                              <div className="gridBox">60</div>
                              <div className="gridBox">120</div>
                              <div className="gridBox">&lt;18.5</div>
                              <div className="gridBox">18.5 to 24.9</div>
                              <div className="gridBox">25 to 29.9</div>
                              <div className="gridBox">&gt;= 30</div>
                              <div className="gridBox">{el?.Source}</div>
                              <div className="gridBox">{el?.Notes}</div>
                              <div className="gridBox">
                                <Icon icon="material-symbols:delete" />
                              </div>
                            </>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
            {tabs === "Temperature" && (
              <>
                {!temperatureData || temperatureData?.length === 0 ? (
                  <CarePlanErrorMessage msg="Patient has not entered data for temperature yet!" />
                ) : (
                  <div className="gridBody grid7">
                    <div className="gridHead">Taken Date & Time</div>
                    <div className="gridHead">Unit </div>
                    <div className="gridHead">Value</div>
                    <div className="gridHead">Source</div>
                    <div className="gridHead">Height</div>
                    <div className="gridHead">Notes</div>
                    <div className="gridHead">Action</div>
                    <div className="gridContent">
                      {loading ? (
                        <>&nbsp;</>
                      ) : (
                        <>
                          {temperatureData?.map((el) => (
                            <>
                              <div className="gridBox">
                                {new Date(el.ts).toLocaleString()}
                              </div>
                              <div className="gridBox">{el?.unit}</div>
                              <div className="gridBox">{el?.value}</div>
                              <div className="gridBox">{el?.Source}</div>
                              <div className="gridBox">{el?.Height}</div>
                              <div className="gridBox">{el?.Notes}</div>
                              <div className="gridBox">
                                <Icon icon="material-symbols:delete" />
                              </div>
                            </>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}

            {tabs === "Blood Glucose" && (
              <CarePlanErrorMessage msg="Patient has not entered data for blood glucose yet!" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VitalsReading;
