import React, { useEffect, useState } from "react";
import LoaderComponent from "../components/LoaderComponent";
import { Icon } from "@iconify/react";
import { generalHandleValueChanges } from "../patients/Careteam/helperfunctions";
import PopUp from "../patients/patientViewPage/PopUp";
import SearchPatientGnrl from "./SearchPatient";
import { useGetPatientsAllProfilesQuery } from "../app/api/patientsApi";
import "./unmapedData.scss";
import FloatingNotifications from "../utilities/FloatingNotifications";
import CarePlanErrorMessage from "../patients/Careteam/CarePlanErrorMessage";
import classNames from "classnames";
import {
  useGetUnmapedDataMutation,
  useMapDataMutation,
} from "../app/api/rpmApi";
const UnmapedData = () => {
  const [popUp, setPopUp] = useState(false);
  const [mapdata, setMapdata] = useState(false);
  const [patients, setPatients] = useState(false);
  const [unMapedData, setUnmapedData] = useState();
  const [tabs, setTabs] = useState("Blood Pressure");
  const [dataFilters, setDataFilters] = useState({ patient: "" });

  const [mapDataFn, { data: mapedData, isLoadingmaping, errorMaping }] =
    useMapDataMutation();
  const [getUnmapedDataFn, { data, isLoading, errorGettingTheData }] =
    useGetUnmapedDataMutation();
  const {
    data: patientsData,
    isLoadingPatients,
    refetch,
  } = useGetPatientsAllProfilesQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  useEffect(() => {
    if (errorMaping) {
      FloatingNotifications("error", "Error mapping the data");
    }
    if (errorGettingTheData) {
      FloatingNotifications("error", "Error getting the data");
    }
  }, [errorMaping, errorGettingTheData]);
  useEffect(() => {
    getUnmapedDataFn();
  }, []);
  useEffect(() => {
    getUnmapedDataFn();
  }, [mapedData]);
  useEffect(() => {
    if (!data) {
      return;
    }
    setUnmapedData(data);
  }, [data]);
  const cancelfn = () => {
    setMapdata();
    setDataFilters({ patient: "" });

    setPopUp(false);
  };
  const addFn = (e) => {
    setMapdata(e);
    setPopUp(true);
  };

  const onClickMapData = () => {
    mapDataFn({
      PatientId: dataFilters.patient,
      data: { ...mapdata },
    });
    setMapdata();
    setDataFilters({ patient: "" });
    setPopUp(false);
  };

  useEffect(() => {
    if (!patientsData) return;
    let result = [
      ...patientsData
        ?.filter((p) =>
          p?.enrolledPrograms?.some((e) => e?.programName === "RPM")
        )
        .map((d) => [d.Name, d.PatientId]),
    ];
    setPatients(result);
  }, [patientsData]);
  return (
    <div className="mainLoyout">
      {popUp && (
        <PopUp cancelfn={cancelfn}>
          <div>
            <p>Select the patient to map this data </p>
            <div className="wrapselect">
              <SearchPatientGnrl
                OptionsDict={patients}
                fn={generalHandleValueChanges}
                setvalue={setDataFilters}
                name={"patient"}
              />
            </div>
            <div className="buttonsContainer">
              <button
                onClick={onClickMapData}
                disabled={dataFilters.patient === "" ? true : false}
              >
                Map
              </button>
              <button onClick={cancelfn}>cancel</button>
            </div>
          </div>
        </PopUp>
      )}
      <LoaderComponent isLoading={isLoading || isLoadingPatients} />
      <div className="title">Pending Rpm Entriies</div>

      <div className="vBody">
        <div className="vtab">
          <div
            onClick={() => setTabs("Blood Pressure")}
            className={classNames(
              "tabBtn",
              tabs === "Blood Pressure" ? "active" : ""
            )}
          >
            <Icon
              icon="healthicons:blood-pressure-2-outline"
              color={tabs === "Blood Pressure" ? "#fff" : "#9b86e3"}
              width="26"
              height="26"
            />
            <p>Blood Pressure</p>
          </div>

          <div
            className={classNames("tabBtn", tabs === "Weight" ? "active" : "")}
            onClick={() => setTabs("Weight")}
          >
            <Icon
              icon="mdi:weight-lifter"
              color={tabs === "Weight" ? "#fff" : "#9b86e3"}
              width="26"
              height="26"
            />
            <p>Weight</p>
          </div>
          <div
            className={classNames(
              "tabBtn",
              tabs === "Temperature" ? "active" : ""
            )}
            onClick={() => setTabs("Temperature")}
          >
            <Icon
              icon="tabler:temperature-sun"
              color={tabs === "Temperature" ? "#fff" : "#9b86e3"}
              width="26"
              height="26"
            />
            <p>Temperature</p>
          </div>
          <div
            className={classNames(
              "tabBtn",
              tabs === "Blood Glucose" ? "active" : ""
            )}
            onClick={() => setTabs("Blood Glucose")}
          >
            <Icon
              icon="fa6-solid:hand-holding-droplet"
              color={tabs === "Blood Glucose" ? "#fff" : "#9b86e3"}
              width="26"
              height="26"
            />
            <p>Blood Glucose</p>
          </div>
        </div>
        <div className="boxContainer">
          <div className="boxGridBody">
            <div className="subNav">
              {/* <div className="period">
                <p className="periodTxt">Period:</p>
                <select placeholder="All" />
              </div> */}
              {/* <p className="note">
                <b>Note:</b> Taken Date & Time displayed below are in patient’s
                time zone (EST)
              </p> */}
            </div>
            {tabs === "Blood Pressure" && (
              <>
                {!unMapedData ||
                !unMapedData?.filter(
                  (e) => e?.device_type === "bpm_gen2_measure"
                )[0]?.deviceData?.length > 0 ? (
                  <CarePlanErrorMessage msg="No data found for Blood Pressure yet!" />
                ) : (
                  <div className="gridBody">
                    <div className="gridHeader">
                      <div className="gridHead">Period</div>
                      <div className="gridHead">Taken Date & Time</div>
                      <div className="gridHead">Systolic Value (mmHg)</div>
                      <div className="gridHead">Diastolic Value (mmHg)</div>
                      <div className="gridHead">Pulse Value (Beats/min)</div>
                      <div className="gridHead">Min Systolic</div>
                      <div className="gridHead">Max Systolic</div>
                      <div className="gridHead">Min Diastolic</div>
                      <div className="gridHead">Max Diastolic</div>
                      <div className="gridHead">Pulse Min Value</div>
                      <div className="gridHead">Pulse Max Value</div>
                      <div className="gridHead">Source</div>
                      <div className="gridHead">Notes</div>
                      <div className="gridHead">Action</div>
                    </div>
                    <div className="gridContent">
                      {isLoading ? (
                        <>&nbsp;</>
                      ) : (
                        <>
                          {unMapedData
                            ?.filter(
                              (e) => e?.device_type === "bpm_gen2_measure"
                            )[0]
                            ?.deviceData?.map((el) => (
                              <>
                                <div className="gridBox">{el?.Period}</div>
                                <div className="gridBox">
                                  {new Date(el.ts).toLocaleString()}
                                </div>
                                <div className="gridBox">{el?.sys}</div>
                                <div className="gridBox">{el?.dia}</div>
                                <div className="gridBox">{el?.pul}</div>
                                <div className="gridBox">100</div>
                                <div className="gridBox">150</div>
                                <div className="gridBox">50</div>
                                <div className="gridBox">100</div>
                                <div className="gridBox">60</div>
                                <div className="gridBox">110</div>
                                <div className="gridBox">{el?.Source}</div>
                                <div className="gridBox">{el?.Notes}</div>
                                <div className="gridBox">
                                  <Icon
                                    icon="mingcute:add-fill"
                                    onClick={() => addFn(el)}
                                  />
                                </div>
                              </>
                            ))}
                        </>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
            {tabs === "Weight" && (
              <>
                {!unMapedData ||
                !unMapedData?.filter(
                  (e) => e?.device_type === "scale_gen2_measure"
                )[0]?.deviceData?.length > 0 ? (
                  <CarePlanErrorMessage msg="No data found for weight yet!" />
                ) : (
                  <div className="gridBody grid12">
                    <div className="gridHead">Taken Date & Time</div>
                    <div className="gridHead">KG Value </div>
                    <div className="gridHead">BMI Value</div>
                    <div className="gridHead">Min Weight</div>
                    <div className="gridHead">Max Weight</div>
                    <div className="gridHead">Underweight</div>
                    <div className="gridHead">Normal</div>
                    <div className="gridHead">Overweight</div>
                    <div className="gridHead">Obese</div>
                    <div className="gridHead">Source</div>
                    <div className="gridHead">Notes</div>
                    <div className="gridHead">Action</div>
                    <div className="gridContent">
                      {isLoading ? (
                        <>&nbsp;</>
                      ) : (
                        <>
                          {unMapedData
                            ?.filter(
                              (e) => e?.device_type === "scale_gen2_measure"
                            )[0]
                            ?.deviceData?.map((el) => (
                              <>
                                <div className="gridBox">
                                  {new Date(el.ts).toLocaleString()}
                                </div>
                                <div className="gridBox">{el?.wt}</div>
                                <div className="gridBox">
                                  {el?.BMI}({el?.BMI_Category})
                                </div>
                                <div className="gridBox">60</div>
                                <div className="gridBox">120</div>
                                <div className="gridBox">&lt;18.5</div>
                                <div className="gridBox">18.5 to 24.9</div>
                                <div className="gridBox">25 to 29.9</div>
                                <div className="gridBox">&gt;= 30</div>
                                <div className="gridBox">{el?.Source}</div>
                                <div className="gridBox">{el?.Notes}</div>
                                <div className="gridBox">
                                  <Icon
                                    icon="mingcute:add-fill"
                                    onClick={() => addFn(el)}
                                  />
                                </div>
                              </>
                            ))}
                        </>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
            {tabs === "Temperature" && (
              <>
                {!unMapedData ||
                !unMapedData?.filter(
                  (e) => e?.device_type === "thermo_measure"
                )[0]?.deviceData?.length > 0 ? (
                  <CarePlanErrorMessage msg="No data found for Temperature yet!" />
                ) : (
                  <div className="gridBody grid7">
                    <div className="gridHead">Taken Date & Time</div>
                    <div className="gridHead">Unit </div>
                    <div className="gridHead">Value</div>
                    <div className="gridHead">Source</div>
                    <div className="gridHead">Height</div>
                    <div className="gridHead">Notes</div>
                    <div className="gridHead">Action</div>
                    <div className="gridContent">
                      {isLoading ? (
                        <>&nbsp;</>
                      ) : (
                        <>
                          {unMapedData
                            ?.filter(
                              (e) => e?.device_type === "thermo_measure"
                            )[0]
                            ?.deviceData?.map((el) => (
                              <>
                                <div className="gridBox">
                                  {new Date(el.ts).toLocaleString()}
                                </div>
                                <div className="gridBox">{el?.unit}</div>
                                <div className="gridBox">{el?.value}</div>
                                <div className="gridBox">{el?.Source}</div>
                                <div className="gridBox">{el?.Height}</div>
                                <div className="gridBox">{el?.Notes}</div>
                                <div className="gridBox">
                                  <Icon
                                    icon="mingcute:add-fill"
                                    onClick={() => addFn(el)}
                                  />
                                </div>
                              </>
                            ))}
                        </>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
            {tabs === "Blood Glucose" && (
              <CarePlanErrorMessage msg="No data found for blood glucose yet!" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnmapedData;
