import React from "react";
import { useState, useEffect } from "react";
import PopUp from "../../patientViewPage/PopUp";
import ThresholdDropContainer from "./ThresholdDropContainer";
import "./setThreshold.scss";
import { switchClasses } from "@mui/material";
function SetThreshold({ popUpOpen, setPopUpOpen, id, tabs }) {
  const [window, setWindow] = useState("display");
  const [windowPulse, setWindowPulse] = useState("display");
  const initialState = {};
  const [title, setTitle] = useState("");
  const cancelfn = () => {
    setPopUpOpen(false);
  };
  useEffect(() => {
    switch (tabs) {
      case "Weight":
        setTitle(" Weight / BMI - Threshold Setup");
        break;
      case "Blood Pressure":
        setTitle(" Blood Pressure Threshold Setup");
        break;

      case "Blood Glucose":
        setTitle("Blood Glucose Threshold Setup");
        break;
      case "Temperature":
        setTitle("Temperature Threshold Setup");
        break;

      default:
        return "";
    }
  }, [tabs]);
  return (
    <div className="setThreshold">
      {popUpOpen && (
        <PopUp cancelfn={cancelfn} width={"80vw"} id={id}>
          <div className="mainContainer">
            <ThresholdDropContainer title={title}>
              <div className="Cont">
                <ThresholdDropContainer
                  title={"Active"}
                  icons={true}
                  setWindow={setWindow}
                >
                  {tabs === "Weight" && window === "display" && (
                    <div className="threDisplay">
                      <div className="strigthDisplay">
                        <p className="Element bold">Effective Date & Time</p>
                        <p className="Element">12-31-2018 09:00 PM</p>
                        <p className="Element bold">Weight Range</p>
                        <p className="Element">85 - 220 (lb)</p>
                      </div>
                      <div className="bmi">
                        <p className="bmiTitle">BMI</p>
                        <div className="displayGrid">
                          <p className="Element bold">Underweight</p>
                          <p className="Element">&lt; 18.5</p>
                          <p className="Element bold">Weight Normal</p>
                          <p className="Element">18.5 to 24.9</p>
                        </div>
                        <div className="displayGrid">
                          <p className="Element bold">Overweight</p>
                          <p className="Element"> 25 to 29.9</p>
                          <p className="Element bold">Obese</p>
                          <p className="Element">&gt;= 30</p>
                        </div>
                      </div>
                    </div>
                  )}
                  {tabs === "Weight" && window === "addetails" && (
                    <div className="addDetails">
                      <div className="addBox ">
                        <div className="elem">
                          <p className="dropElementsLabel">Min *</p>
                          <input
                            type="text"
                            // value={device.deviceSN}
                            // onChange={(event) =>
                            //   generalHandleValueChanges(
                            //     event,
                            //     setDevices,
                            //     "deviceSN"
                            //   )
                            // }
                          />
                        </div>
                        <div className="elem">
                          <p className="dropElementsLabel">Max *</p>
                          <input
                            type="text"
                            // value={device.deviceSN}
                            // onChange={(event) =>
                            //   generalHandleValueChanges(
                            //     event,
                            //     setDevices,
                            //     "deviceSN"
                            //   )
                            // }
                          />
                        </div>
                      </div>
                      <div className="addBox">
                        <div className="elem">
                          <p className="dropElementsLabel">Underweight *</p>
                          <input
                            type="text"
                            // value={device.deviceSN}
                            // onChange={(event) =>
                            //   generalHandleValueChanges(
                            //     event,
                            //     setDevices,
                            //     "deviceSN"
                            //   )
                            // }
                          />
                        </div>
                        <div className="elem">
                          <p className="dropElementsLabel">Normal *</p>
                          <input
                            type="text"
                            // value={device.deviceSN}
                            // onChange={(event) =>
                            //   generalHandleValueChanges(
                            //     event,
                            //     setDevices,
                            //     "deviceSN"
                            //   )
                            // }
                          />
                        </div>
                        <div className="elem">
                          <p className="dropElementsLabel">Overweight *</p>
                          <input
                            type="text"
                            // value={device.deviceSN}
                            // onChange={(event) =>
                            //   generalHandleValueChanges(
                            //     event,
                            //     setDevices,
                            //     "deviceSN"
                            //   )
                            // }
                          />
                        </div>
                        <div className="elem">
                          <p className="dropElementsLabel">Obese *</p>
                          <input
                            type="text"
                            // value={device.deviceSN}
                            // onChange={(event) =>
                            //   generalHandleValueChanges(
                            //     event,
                            //     setDevices,
                            //     "deviceSN"
                            //   )
                            // }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {tabs === "Blood Pressure" && window === "display" && (
                    <div className="threDisplay">
                      <div className="strigthDisplay">
                        <p className="Element bold">Effective Date & Time</p>
                        <p className="Element">12-31-2018 09:00 PM</p>
                      </div>
                      <div className="pressure">
                        <div className="grid2">
                          <p className="gridHead">Systolic Pressure (mmHg)</p>
                          <p className="Element bold">Wake up Range</p>
                          <p className="Element">100 - 150 mmHg</p>
                          <p className="Element bold">Morning Range</p>
                          <p className="Element">100 - 150 mmHg</p>
                          <p className="Element bold">Noon Range</p>
                          <p className="Element"> 100 - 150 mmHg</p>
                          <p className="Element bold">Afternoon Range</p>
                          <p className="Element">100 - 150 mmHg</p>
                          <p className="Element bold">Evening Range</p>
                          <p className="Element">100 - 150 mmHg</p>
                        </div>
                        <div className="grid2">
                          <p className="gridHead">Diastolic Pressure (mmHg)</p>
                          <p className="Element bold">Wake up Range</p>
                          <p className="Element">50 - 100 mmHg</p>
                          <p className="Element bold">Morning Range</p>
                          <p className="Element">50 - 100 mmHg</p>
                          <p className="Element bold">Noon Range</p>
                          <p className="Element">50 - 100 mmHg</p>
                          <p className="Element bold">Afternoon Range</p>
                          <p className="Element"> 50 - 100 mmHg</p>
                          <p className="Element bold">Evening Range</p>
                          <p className="Element">50 - 100 mmHg</p>
                        </div>
                      </div>
                    </div>
                  )}
                  {tabs === "Blood Pressure" && window === "addetails" && (
                    <div className=" bloodPressure">
                      <div className="addBoxPressure ">
                        <p className="head">Systolic Pressure (mmHg)</p>
                        <div className="elem">
                          <p className="dropElementsLabel">Wake up Range *</p>
                          <input
                            placeholder="MIN"
                            type="text"
                            // value={device.deviceSN}
                            // onChange={(event) =>
                            //   generalHandleValueChanges(
                            //     event,
                            //     setDevices,
                            //     "deviceSN"
                            //   )
                            // }
                          />
                        </div>
                        <div className="elem">
                          <p className="dropElementsLabel hide">Max *</p>
                          <input
                            placeholder="MAX"
                            type="text"
                            // value={device.deviceSN}
                            // onChange={(event) =>
                            //   generalHandleValueChanges(
                            //     event,
                            //     setDevices,
                            //     "deviceSN"
                            //   )
                            // }
                          />
                        </div>
                        <div className="elem">
                          <p className="dropElementsLabel">Morning Range *</p>
                          <input
                            placeholder="MIN"
                            type="text"
                            // value={device.deviceSN}
                            // onChange={(event) =>
                            //   generalHandleValueChanges(
                            //     event,
                            //     setDevices,
                            //     "deviceSN"
                            //   )
                            // }
                          />
                        </div>
                        <div className="elem">
                          <p className="dropElementsLabel hide">Max *</p>
                          <input
                            placeholder="MAX"
                            type="text"
                            // value={device.deviceSN}
                            // onChange={(event) =>
                            //   generalHandleValueChanges(
                            //     event,
                            //     setDevices,
                            //     "deviceSN"
                            //   )
                            // }
                          />
                        </div>
                        <div className="elem">
                          <p className="dropElementsLabel">Noon Range *</p>
                          <input
                            placeholder="MIN"
                            type="text"
                            // value={device.deviceSN}
                            // onChange={(event) =>
                            //   generalHandleValueChanges(
                            //     event,
                            //     setDevices,
                            //     "deviceSN"
                            //   )
                            // }
                          />
                        </div>
                        <div className="elem">
                          <p className="dropElementsLabel hide">Max *</p>
                          <input
                            placeholder="MAX"
                            type="text"
                            // value={device.deviceSN}
                            // onChange={(event) =>
                            //   generalHandleValueChanges(
                            //     event,
                            //     setDevices,
                            //     "deviceSN"
                            //   )
                            // }
                          />
                        </div>
                        <div className="elem">
                          <p className="dropElementsLabel">Afternoon Range *</p>
                          <input
                            placeholder="MIN"
                            type="text"
                            // value={device.deviceSN}
                            // onChange={(event) =>
                            //   generalHandleValueChanges(
                            //     event,
                            //     setDevices,
                            //     "deviceSN"
                            //   )
                            // }
                          />
                        </div>
                        <div className="elem">
                          <p className="dropElementsLabel hide">Max *</p>
                          <input
                            placeholder="MAX"
                            type="text"
                            // value={device.deviceSN}
                            // onChange={(event) =>
                            //   generalHandleValueChanges(
                            //     event,
                            //     setDevices,
                            //     "deviceSN"
                            //   )
                            // }
                          />
                        </div>
                        <div className="elem">
                          <p className="dropElementsLabel">Evening Range *</p>
                          <input
                            placeholder="MIN"
                            type="text"
                            // value={device.deviceSN}
                            // onChange={(event) =>
                            //   generalHandleValueChanges(
                            //     event,
                            //     setDevices,
                            //     "deviceSN"
                            //   )
                            // }
                          />
                        </div>
                        <div className="elem">
                          <p className="dropElementsLabel hide">Max *</p>
                          <input
                            placeholder="MAX"
                            type="text"
                            // value={device.deviceSN}
                            // onChange={(event) =>
                            //   generalHandleValueChanges(
                            //     event,
                            //     setDevices,
                            //     "deviceSN"
                            //   )
                            // }
                          />
                        </div>
                      </div>
                      <div className="addBoxPressure ">
                        <p className="head">Diastolic Pressure (mmHg)</p>

                        <div className="elem">
                          <p className="dropElementsLabel">Wake up Range *</p>
                          <input
                            placeholder="MIN"
                            type="text"
                            // value={device.deviceSN}
                            // onChange={(event) =>
                            //   generalHandleValueChanges(
                            //     event,
                            //     setDevices,
                            //     "deviceSN"
                            //   )
                            // }
                          />
                        </div>
                        <div className="elem">
                          <p className="dropElementsLabel hide">Max *</p>
                          <input
                            placeholder="MAX"
                            type="text"
                            // value={device.deviceSN}
                            // onChange={(event) =>
                            //   generalHandleValueChanges(
                            //     event,
                            //     setDevices,
                            //     "deviceSN"
                            //   )
                            // }
                          />
                        </div>
                        <div className="elem">
                          <p className="dropElementsLabel">Morning Range *</p>
                          <input
                            placeholder="MIN"
                            type="text"
                            // value={device.deviceSN}
                            // onChange={(event) =>
                            //   generalHandleValueChanges(
                            //     event,
                            //     setDevices,
                            //     "deviceSN"
                            //   )
                            // }
                          />
                        </div>
                        <div className="elem">
                          <p className="dropElementsLabel hide">Max *</p>
                          <input
                            placeholder="MAX"
                            type="text"
                            // value={device.deviceSN}
                            // onChange={(event) =>
                            //   generalHandleValueChanges(
                            //     event,
                            //     setDevices,
                            //     "deviceSN"
                            //   )
                            // }
                          />
                        </div>
                        <div className="elem">
                          <p className="dropElementsLabel">Noon Range *</p>
                          <input
                            placeholder="MIN"
                            type="text"
                            // value={device.deviceSN}
                            // onChange={(event) =>
                            //   generalHandleValueChanges(
                            //     event,
                            //     setDevices,
                            //     "deviceSN"
                            //   )
                            // }
                          />
                        </div>
                        <div className="elem">
                          <p className="dropElementsLabel hide">Max *</p>
                          <input
                            placeholder="MAX"
                            type="text"
                            // value={device.deviceSN}
                            // onChange={(event) =>
                            //   generalHandleValueChanges(
                            //     event,
                            //     setDevices,
                            //     "deviceSN"
                            //   )
                            // }
                          />
                        </div>
                        <div className="elem">
                          <p className="dropElementsLabel">Afternoon Range *</p>
                          <input
                            placeholder="MIN"
                            type="text"
                            // value={device.deviceSN}
                            // onChange={(event) =>
                            //   generalHandleValueChanges(
                            //     event,
                            //     setDevices,
                            //     "deviceSN"
                            //   )
                            // }
                          />
                        </div>
                        <div className="elem">
                          <p className="dropElementsLabel hide">Max *</p>
                          <input
                            placeholder="MAX"
                            type="text"
                            // value={device.deviceSN}
                            // onChange={(event) =>
                            //   generalHandleValueChanges(
                            //     event,
                            //     setDevices,
                            //     "deviceSN"
                            //   )
                            // }
                          />
                        </div>
                        <div className="elem">
                          <p className="dropElementsLabel">Evening Range *</p>
                          <input
                            placeholder="MIN"
                            type="text"
                            // value={device.deviceSN}
                            // onChange={(event) =>
                            //   generalHandleValueChanges(
                            //     event,
                            //     setDevices,
                            //     "deviceSN"
                            //   )
                            // }
                          />
                        </div>
                        <div className="elem">
                          <p className="dropElementsLabel hide">Max *</p>
                          <input
                            placeholder="MAX"
                            type="text"
                            // value={device.deviceSN}
                            // onChange={(event) =>
                            //   generalHandleValueChanges(
                            //     event,
                            //     setDevices,
                            //     "deviceSN"
                            //   )
                            // }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </ThresholdDropContainer>
                <ThresholdDropContainer
                  title={"History"}
                  open={false}
                ></ThresholdDropContainer>
              </div>
            </ThresholdDropContainer>
            {tabs === "Blood Pressure" && (
              <ThresholdDropContainer title={"Pulse Threshold Setup"}>
                <div className="Cont">
                  <ThresholdDropContainer
                    title={"Active"}
                    icons={true}
                    setWindow={setWindowPulse}
                  >
                    {windowPulse === "display" && (
                      <div className="threDisplay">
                        <div className="strigthDisplay ">
                          <p className="Element bold">Effective Date & Time</p>
                          <p className="Element">12-31-2018 09:00 PM</p>
                          <p className="Element bold">Pulse</p>
                          <p className="Element">Pulse60 - 110 (Beats/Min)</p>
                        </div>
                      </div>
                    )}
                    {windowPulse === "addetails" && (
                      <div className="bloodPressure">
                        <div className="addBoxPressure ">
                          <div className="elem">
                            <p className="dropElementsLabel">
                              Pulse (Beats/Min) *
                            </p>
                            <input
                              placeholder="MIN"
                              type="text"
                              // value={device.deviceSN}
                              // onChange={(event) =>
                              //   generalHandleValueChanges(
                              //     event,
                              //     setDevices,
                              //     "deviceSN"
                              //   )
                              // }
                            />
                          </div>
                          <div className="elem">
                            <p className="dropElementsLabel hide">Max *</p>
                            <input
                              placeholder="MAX"
                              type="text"
                              // value={device.deviceSN}
                              // onChange={(event) =>
                              //   generalHandleValueChanges(
                              //     event,
                              //     setDevices,
                              //     "deviceSN"
                              //   )
                              // }
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </ThresholdDropContainer>
                  <ThresholdDropContainer
                    title={"History"}
                    open={false}
                  ></ThresholdDropContainer>
                </div>
              </ThresholdDropContainer>
            )}
          </div>
        </PopUp>
      )}
    </div>
  );
}

export default SetThreshold;
