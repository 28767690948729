import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { Icon } from "@iconify/react";
import "../patients/searchandselect component/SearchAndSelect.scss";
import { useEffect, useMemo } from "react";

const SearchPatient = ({ options, OptionsDict, fn, setvalue, name }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  const [isOpen, setIsOpen] = useState(false);

  const filteredOptions = useMemo(() => {
    if (options) {
      return options?.filter((option) =>
        option.toLowerCase().includes(searchTerm.toLowerCase())
      );
    } else if (OptionsDict) {
      return OptionsDict?.filter((option) =>
        option[0].toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
  }, [options, searchTerm, OptionsDict]);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleOptionClick = (option, option2) => {
    if (OptionsDict) {
      setSelectedOption(option2);
      setSearchTerm(option2);
      setIsOpen(false);
      fn(option, setvalue, name);
      return;
    }
    setSelectedOption(option);
    setSearchTerm(option);
    setIsOpen(false);
    fn(option, setvalue, name);
  };
  const firstClick = () => {
    setIsOpen(true);
  };
  const clear = () => {
    setIsOpen(true);
    setSearchTerm("");
    setSelectedOption("");
  };

  return (
    <div className="select-with-search">
      <OutsideClickHandler
        onOutsideClick={() => {
          setIsOpen(false);
        }}
      >
        <div className="dropdown">
          <div className="dropFlex">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleInputChange}
              onClick={() => firstClick()}
            />
            <button className="close" onClick={clear}>
              {selectedOption ? (
                <Icon icon="iconoir:cancel" />
              ) : (
                <Icon icon="ic:sharp-search" />
              )}
            </button>
          </div>

          {isOpen && OptionsDict && (
            <ul className="options" size="5">
              {filteredOptions?.map((option, index) => (
                <li
                  key={index}
                  onClick={() => handleOptionClick(option[1], option[0])}
                >
                  {option[0]}
                </li>
              ))}
            </ul>
          )}
        </div>
      </OutsideClickHandler>
    </div>
  );
};

const SearchPatientGnrl = React.memo(SearchPatient);
export default SearchPatientGnrl;
