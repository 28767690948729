import { apiCollection } from "./apiCollection";

const RpmApi = apiCollection.injectEndpoints({
  endpoints: (build) => ({
    addDevice: build.mutation({
      query: (payload) => {
        // console.log(payload);
        return {
          url: "/rpm/updateDeviceDetails",
          method: "POST",
          body: payload,
        };
      },
      transformErrorResponse: (response, meta, arg) => response.data.message,
    }),
    getDeviceDetails: build.mutation({
      query: (payload) => {
        console.log(payload);
        return {
          url: "/rpm/exisitingDevice",
          method: "POST",
          body: payload,
        };
      },
      transformErrorResponse: (response, meta, arg) => response.data.message,
    }),
    getDeviceDeta: build.mutation({
      query: (payload) => {
        return {
          url: "/rpm/exisitingDeviceData",
          method: "POST",
          body: payload,
        };
      },
      transformErrorResponse: (response, meta, arg) => response.data.message,
    }),
    updateDeviceDeta: build.mutation({
      query: (payload) => {
        return {
          url: "/rpm/manualDeviceData",
          method: "POST",
          body: payload,
        };
      },
      transformErrorResponse: (response, meta, arg) => response.data.message,
    }),
    getUnmapedData: build.mutation({
      query: (payload) => {
        return {
          url: "/rpm/unmatchedDeviceData",
          method: "POST",
          body: payload,
        };
      },
      transformErrorResponse: (response, meta, arg) => response.data.message,
    }),
    mapData: build.mutation({
      query: (payload) => {
        return {
          url: "/rpm/mapDeviceData",
          method: "POST",
          body: payload,
        };
      },
      transformErrorResponse: (response, meta, arg) => response.data.message,
    }),
  }),
  overrideExisting: false,
});

export const {
  useAddDeviceMutation,
  useGetDeviceDetailsMutation,
  useGetDeviceDetaMutation,
  useUpdateDeviceDetaMutation,
  useGetUnmapedDataMutation,
  useMapDataMutation,
} = RpmApi;
